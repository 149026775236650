import Airtable from 'airtable'

type Config = {
  AIRTABLE_API_KEY: string
  AIRTABLE_BASE: string
  JWT_SECRET: string
  PRISMIC_ENDPOINT: string
  PRISMIC_API_KEY: string
  SLACK_WEB_HOOK: string
  airtable: any
}

export const config: Config = {
  AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY,
  AIRTABLE_BASE: process.env.AIRTABLE_BASE,
  JWT_SECRET: process.env.JWT_SECRET,
  PRISMIC_ENDPOINT: process.env.PRISMIC_ENDPOINT,
  PRISMIC_API_KEY: process.env.PRISMIC_API_KEY,
  SLACK_WEB_HOOK: process.env.SLACK_WEB_HOOK,
  airtable: null,
}

config.airtable = process.env.AIRTABLE_API_KEY ? new Airtable({ apiKey: config.AIRTABLE_API_KEY }).base(
  config.AIRTABLE_BASE,
) : null