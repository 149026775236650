import React, { createContext, useReducer } from 'react'
import { get } from 'lodash'
import * as ls from 'local-storage'
import * as Sentry from '@sentry/node'

export type UserContextType = {
  user: any
}

export const userContext: UserContextType = {
  user: null,
}

export const USER_REDUCERS = {
  logout: 'LOGOUT',
  updateUser: 'UPDATE_USER',
}

export const userReducer = (state, action) => {
  switch (action.type) {
    case USER_REDUCERS.updateUser:
      if (get(action, 'payload.first_name') && get(action, 'payload.last_name')) {
        Sentry.setUser({ username: `${action.payload.first_name} ${action.payload.last_name}` })
      }

      return {
        ...state,
        user: action.payload,
      }
    case USER_REDUCERS.logout:
      // Remove Access Token
      // Refresh Page
      ls.remove('accessToken')
      return {
        ...state,
        user: null,
      }
    default:
      throw new Error()
  }
}

export const UserContext = createContext(userContext)

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, userContext)

  return (
    /*
    // @ts-ignore */
    <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>
  )
}