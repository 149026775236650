import { Howl, Howler } from 'howler'

export type AudioState = {
  ready: boolean
  loading: boolean
  playing: boolean
  ended: boolean
  duration: number
}

export class AudioPlayer {
  sound: Howl
  state: AudioState

  stateListener: (state: AudioState) => void

  constructor() {
    this.sound = null
    this.state = {
      ready: false,
      loading: false,
      playing: false,
      ended: false,
      duration: 0,
    }
  }

  playUrl(url) {
    if (this.sound) {
      this.sound.stop()
      this.sound.unload()
      this.state = {
        ...this.state,
        loading: true,
        playing: false,
        ended: false,
        duration: 0,
      }
      this.stateListener(this.state)
    }

    this.sound = new Howl({
      src: [url],
      autoplay: true,
      html5: true,
      onload: () => {
        this.state = {
          ...this.state,
          loading: false,
          duration: this.sound.duration(),
        }
        this.stateListener(this.state)
      },
      onunlock: () => {
        this.state = {
          ...this.state,
          ready: true,
        }
        this.stateListener(this.state)
      },
      onend: () => {
        this.state = {
          ...this.state,
          ended: true,
          playing: false,
        }
        this.stateListener(this.state)
      },
      onplay: () => {
        this.state = {
          ...this.state,
          ready: true,
          playing: true,
        }
        this.stateListener(this.state)
      },
      onstop: () => {
        this.state = {
          ...this.state,
          playing: false,
        }
        this.stateListener(this.state)
      },
    })
  }

  setVolume(volume) {
    // Howler.volume(volume)
    Howler.mute(volume > 0 ? false : true)
  }

  play() {
    if (this.sound && !this.sound.playing()) {
      this.sound.play()
    }
  }

  stop() {
    if (this.sound && this.sound.playing()) {
      this.sound.stop()
    }
  }

  setStateListener(listener) {
    this.stateListener = listener
  }

  getPosition() {
    if (this.sound) {
      return this.sound.seek()
    }

    return 0
  }
}
